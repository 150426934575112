<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="archiveProductModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add or edit campaign links</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body mt-4">
            <div v-if="!validated">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="font-weight-bold">Recipients email addresses</div>
                  <div><textarea rows=6 v-model="rawRecipientList" /></div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mt-4">
                    <div class="font-weight-bold">Number of unassigned links</div>
                    <div><input type="number" class="inputfield" min="0" v-model="newLinkCount"></div>
                  </div>
                  <div class="mt-4">
                    <div class="font-weight-bold">
                      One reusable link active
                      <img id="reusable_link-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                      <b-tooltip target="reusable_link-tooltip" triggers="hover">
                        This alternative is not recommended unless you collect payments from recipients.
                      </b-tooltip>
                    </div>
                    <v-select
                        label="name"
                        v-model="multiInvitation"
                        :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                        :reduce="s => s.value"
                        :clearable="false"
                        placeholder="Select"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col">
                  <div class="font-weight-bold">Valid emails found</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-break mb-1" v-for="email of emailArray" v-bind:key="email">
                  <div style="float: left;">{{email}}</div>
                  <div class="round-bg pointer ml-2" v-on:click="removeEmail(email)">x</div>
                </div>
              </div>
              <div class="row">
                <div class="col mt-3">
                  <div class="font-weight-bold">Invalid or duplicate emails</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-break mb-1" v-for="nv of notValid" v-bind:key="nv">
                  {{nv}}
                </div>
              </div>
              <div class="row">
                <div class="col mt-3">
                  <div class="font-weight-bold">Number of unassigned links</div>
                  <div>
                    {{newLinkCount}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mt-3">
                  <div class="font-weight-bold">One reusable link active</div>
                  <div>
                    {{ multiInvitation ? 'Yes' : 'No' }}
                  </div>
                </div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
              <button type="button" class="btn btn-lg btn-outline-primary mt-2 flex-fill w-25" v-on:click="closeModal()">Cancel</button>
              <button v-if="!validated" type="button" class="btn btn-lg btn-primary mt-2 flex-fill w-25" v-on:click="validate()">Next</button>
              <button v-else type="button" class="btn btn-lg btn-primary mt-2 flex-fill w-25" v-on:click="addToCampaign()">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "AddMoreLinksModal",
  props: [
    "emails",
    "notValid",
    "multiInvitationActive"
  ],
  data(){
    return {
      validated: false,
      newLinkCount: 0,
      rawRecipientList: "",
      multiInvitation: this.multiInvitationActive
    }
  },
  computed: {
    emailArray(){
      return Array.from(this.emails);
    }
  },
  methods: {
    removeEmail(email){
      // remove this email from the set
      this.$emit('removeEmailFromList', email);
    },
    closeModal(){
      this.$emit('closeMoreLinksModalAndClear');
    },
    addToCampaign(){
      this.$emit('addMoreLinksToCampaign', this.newLinkCount, this.multiInvitation);
    },
    validate(){
      // send rawRecipientList to parent for validation/splitting
      this.$emit('validateEmailsToAdd', this.rawRecipientList);
      this.validated = true;
    }
  }
}
</script>
