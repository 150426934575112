<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 mt-md-3">
          <h2 class="mt-2">Default template</h2>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Template name*</div>
          <input type="text" class="inputfield" v-model="template.name">
          <div v-if="$v.template.name.$error">
            <div class="form-field-error" v-if="!$v.template.name.required">This field is required.</div>
          </div>
          <div v-show="bgImageSet">
            <div class="font-weight-bold mt-4">Background image position <img id="bgpos-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="bgpos-tooltip" triggers="hover">
                You can position the background-image to the top, center or bottom of the page.
              </b-tooltip>
            </div>
            <v-select
              label="name"
              v-model="template.bg_image_position"
              :options="bgPositionOptions"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select"
            ></v-select>
            <div v-if="$v.template.bg_image_position.$error">
              <div class="form-field-error" v-if="!$v.template.bg_image_position.required">This field is required.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Background color* <img id="bgcolor-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="bgcolor-tooltip" triggers="hover">
              Background colour will be used on introduction and order confirmation emails and in the Magic Link pages.
            </b-tooltip>
          </div>
          <div class="color-picker-container" ref="background_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.background_color}" v-on:click="openBgPicker('background_colorpicker')"></div>
            <div v-if="showBgPicker && colorPickerRef === 'background_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.background_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.background_color.$error">
            <div class="form-field-error" v-if="!$v.template.background_color.required">This field is required.</div>
          </div>
          <div v-show="bgImageSet">
            <div class="font-weight-bold mt-4">Background image repeat* <img id="repeat-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="repeat-tooltip" triggers="hover">
                Background image can be repeated horizontally and/or vertically or in a way that it's only used once. If only once, then the background color becomes visible after the image ends.
              </b-tooltip>
            </div>
            <v-select
              label="name"
              v-model="template.bg_image_repeat"
              :options="bgRepeatOptions"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select"
            ></v-select>
            <div v-if="$v.template.bg_image_repeat.$error">
              <div class="form-field-error" v-if="!$v.template.bg_image_repeat.required">This field is required.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Background image <img id="addr-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="addr-tooltip" triggers="hover">
              In addition to the background colour, you can also use background images. to customise. Allowed formats are JPG, PNG, GIF and WebP.
            </b-tooltip>
          </div>        
          <div v-show="!bgImgFileName">
            <label for="printFile" class="custom-file-upload mt-0">
              <i class="fa fa-cloud-upload"></i> Choose file
            </label>
            <input class="inputfile sam-file-input" type="file" id="printFile" ref="bgImgFile" v-on:change="handleBgImgFile()"/>
          </div>
          <div v-if="bgImgFileName" style="min-height: 42px;" class="wrap-text d-flex align-items-center sam-file">
            <a v-if="template.background_image" class="float-left mr-1" :href="template.background_image">{{bgImgFileName}}</a>
            <span v-else class="float-left mr-1 align-center file-name">{{bgImgFileName}}</span>
            <div class="pointer">
              <span class="remove-x d-flex align-items-center justify-content-center" v-on:click="removeBgImgFile()">
                <img src="../../../assets/images/cross.svg"/>
              </span>
            </div>
          </div>
          <div v-show="bgImageSet">
            <div class="font-weight-bold mt-4">Background size <img id="size-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="size-tooltip" triggers="hover">
                You can also set the size of the background image.
                Auto is the original size of your image, contain makes the image full visible according to the screen size and cover resizes the background image to cover the entire container,
                even if it has to stretch the image or cut a little bit off one of the edges.
              </b-tooltip>
            </div>
            <v-select
              label="name"
              v-model="template.bg_image_size"
              :options="bg_image_sizeOptions"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select"
            ></v-select>
            <div v-if="$v.template.bg_image_size.$error">
              <div class="form-field-error" v-if="!$v.template.bg_image_size.required">This field is required.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Container background color* <img id="con-bg-col-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="con-bg-col-tooltip" triggers="hover">
              Container is the section where the main texts and images are placed. If you choose a colourful or dark background colour or image, it’s smart to keep this container colour light.
            </b-tooltip>
          </div>
          <div class="color-picker-container" ref="conbackground_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.container_bg_color}" v-on:click="openBgPicker('conbackground_colorpicker')"></div>
            <div v-if="showConBgPicker && colorPickerRef === 'conbackground_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.container_bg_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.container_bg_color.$error">
            <div class="form-field-error" v-if="!$v.template.container_bg_color.required">This field is required.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-3 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-md-3">
          <h2 class="mt-2">Campaign header</h2>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Header background*</div>
           <div class="color-picker-container" ref="header_bg_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.header_background}" v-on:click="openBgPicker('header_bg_colorpicker')"></div>
            <div v-if="showHeaderBgPicker && colorPickerRef === 'header_bg_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.header_background" @input="updateFromPicker" ></c-picker></div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Header text color*</div>
          <div class="color-picker-container" ref="header_text_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.header_font_color}" v-on:click="openBgPicker('header_text_colorpicker')"></div>
            <div v-if="showHeaderTextPicker && colorPickerRef === 'header_text_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.header_font_color" @input="updateFromPicker" ></c-picker></div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Header logo <img id="logo-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="logo-tooltip" triggers="hover">
              Customise the theme with another logo than is in your store. Max file size 500kb
            </b-tooltip>
          </div>
          <div v-show="!logoFileName">
            <label for="logoFile" class="custom-file-upload mt-0">
              <i class="fa fa-cloud-upload"></i> Choose file
            </label>
            <input class="inputfile" type="file" id="logoFile" ref="logoFile" v-on:change="handleLogoFile()"/>
          </div>
          <div v-if="logoFileName" style="min-height: 42px;" class="wrap-text">
            <a v-if="template.logo" class="float-left mr-1" :href="template.logo">{{logoFileName}}</a>
            <span v-else class="float-left mr-1 file-name">{{logoFileName}}</span>
            <div class="pointer">
              <span class="remove-x align-top" v-on:click="removeLogoFile()">X</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-3 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-md-3">
          <h2 class="mt-2">Fonts and texts</h2>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Title (H1, H2 etc) text color*</div>
          <div class="color-picker-container" ref="text_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.font_color_title}" v-on:click="openBgPicker('text_colorpicker')"></div>
            <div v-if="showTextPicker && colorPickerRef === 'text_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.font_color_title" @input="updateFromPicker" ></c-picker></div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Primary (paragraph) text color*</div>
           <div class="color-picker-container" ref="text_body_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.font_color_body}" v-on:click="openBgPicker('text_body_colorpicker')"></div>
            <div v-if="showTextBodyPicker && colorPickerRef === 'text_body_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.font_color_body" @input="updateFromPicker" ></c-picker></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-3 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-md-3">
          <h2 class="mt-2">Forms</h2>
        </div>
      </div>
        <div class="row mb-2">
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Primary button background color*</div>
          <div class="color-picker-container" ref="button_primary_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_primary_bg_color}" v-on:click="openBgPicker('button_primary_colorpicker')"></div>
            <div v-if="showButtonPrimaryPicker && colorPickerRef === 'button_primary_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.button_primary_bg_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_primary_bg_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_primary_bg_color.required">This field is required.</div>
          </div>
          <div class="font-weight-bold mt-4">Second. button background color*</div>
          <div class="color-picker-container" ref="button_secondary_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_secondary_bg_color}" v-on:click="openBgPicker('button_secondary_colorpicker')"></div>
            <div v-if="showButtonSecondaryPicker && colorPickerRef === 'button_secondary_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.button_secondary_bg_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_secondary_bg_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_secondary_bg_color.required">This field is required.</div>
          </div>
          <div class="font-weight-bold mt-4">Button border radius* <img id="border-radius-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="border-radius-tooltip" triggers="hover">
              Customise the button border radius style. Examples are 10px, 25%, 50%.
            </b-tooltip>
          </div>
          <input type="text" class="inputfield" v-model="template.button_border_radius">
          <div v-if="$v.template.button_border_radius.$error">
            <div class="form-field-error" v-if="!$v.template.button_border_radius.required">This field is required.</div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Primary button text color*</div>
          <div class="color-picker-container" ref="button_primary_text_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_primary_font_color}" v-on:click="openBgPicker('button_primary_text_colorpicker')"></div>
            <div v-if="showButtonPrimaryTextPicker && colorPickerRef === 'button_primary_text_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.button_primary_font_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_primary_font_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_primary_font_color.required">This field is required.</div>
          </div>
          <div class="font-weight-bold mt-4">Secondary button text color*</div>
           <div class="color-picker-container" ref="button_secondary_text_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_secondary_font_color}" v-on:click="openBgPicker('button_secondary_text_colorpicker')"></div>
            <div v-if="showButtonSecondaryTextPicker && colorPickerRef === 'button_secondary_text_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.button_secondary_font_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_secondary_font_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_secondary_font_color.required">This field is required.</div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="font-weight-bold">Primary button border-color*</div>
          <div class="color-picker-container" ref="button_primary_border_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_primary_border_color}" v-on:click="openBgPicker('button_primary_border_colorpicker')"></div>
            <div v-if="showButtonPrimaryBorderPicker && colorPickerRef === 'button_primary_border_colorpicker' " style="z-index:100;" class="position-absolute"><c-picker :value="template.button_primary_border_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_primary_border_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_primary_border_color.required">This field is required.</div>
          </div>
          <div class="font-weight-bold mt-4">Secondary button border-color*</div>
          <div class="color-picker-container" ref="button_secondary_border_colorpicker">
            <div class="color-picker-input pointer" :style="{ 'background-color': template.button_secondary_border_color}" v-on:click="openBgPicker('button_secondary_border_colorpicker')"></div>
            <div v-if="showButtonSecondaryBorderPicker && colorPickerRef === 'button_secondary_border_colorpicker'" style="z-index:100;" class="position-absolute"><c-picker :value="template.button_secondary_border_color" @input="updateFromPicker" ></c-picker></div>
          </div>
          <div v-if="$v.template.button_secondary_border_color.$error">
            <div class="form-field-error" v-if="!$v.template.button_secondary_border_color.required">This field is required.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-3 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveTemplate()">{{ editTemplate ? "Save template" : "Create template"}}</button>
        </div>
        <div class="col-12 col-md-3">
          <router-link :to="{ name: 'campaigns', params: { tab: 'templates'}}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to all templates</button>
          </router-link>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditTemplate',
  data() {
    return {
      updating: false,
      template: {
        name: "",
        background_color: '#ffffff',
        background_image: null,
        container_bg_color: '#ffffff',
        bg_image_position: null,
        bg_image_repeat: null,
        bg_image_size: null,
        header_background: '#ffffff',
        header_font_color: '#000000',
        font_color_title: '#000000',
        font_color_body: '#000000',
        button_primary_bg_color: '#000000',
        button_primary_font_color: '#ffffff',
        button_primary_border_color: '#000000',
        button_secondary_bg_color: '#f0f0f0',
        button_secondary_font_color: '#000000',
        button_secondary_border_color: '#f0f0f0',
        button_border_radius: '0px',

      },
      acceptedMimeTypes: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/webp'
      ],
      bgImgFileObj: null,
      bgImgFileChanged: false,
      logoFileObj: null,
      logoFileChanged: false,
      colorPickerRef: null,
      showBgPicker: false,
      showTextPicker: false,
      showTextBodyPicker: false,
      showConBgPicker: false,
      showButtonPrimaryPicker: false,
      showButtonSecondaryPicker: false,
      showButtonPrimaryTextPicker: false,
      showButtonSecondaryTextPicker: false,
      showButtonPrimaryBorderPicker: false,
      showButtonSecondaryBorderPicker: false,
      showButtonBorderRadius: false,
      showHeaderBgPicker: false,
      showHeaderTextPicker: false,
      bgPositionOptions: [
        {name: 'left top', value: 'left top'},
        {name: 'left center', value: 'left center'},
        {name: 'left bottom', value: 'left bottom'},
        {name: 'right top', value: 'right top'},
        {name: 'right center', value: 'right center'},
        {name: 'right bottom', value: 'right bottom'},
        {name: 'center top', value: 'center top'},
        {name: 'center center', value: 'center center'},
        {name: 'center bottom', value: 'center bottom'}
      ],
      bgRepeatOptions: [
        {name: 'repeat', value: 'repeat'},
        {name: 'repeat-x', value: 'repeat-x'},
        {name: 'repeat-y', value: 'repeat-y'},
        {name: 'no-repeat', value: 'no-repeat'}
      ],
      bg_image_sizeOptions: [
        {name: 'auto', value: 'auto'},
        {name: 'contain', value: 'contain'},
        {name: 'cover', value: 'cover'}
      ]
    }
  },
validations: {
    template: {
      name: {
        required
      },
      background_color: {
        required
      },
      container_bg_color: {
        required
      },
      bg_image_position: {
        required: requiredIf(function(){
          return this.bgImageSet;
        }),
      },
      bg_image_repeat: {
        required: requiredIf(function(){
          return this.bgImageSet;
        }),
      },
      bg_image_size: {
        required: requiredIf(function(){
          return this.bgImageSet;
        }),
      },
      button_primary_bg_color: {
        required
      },
      button_secondary_bg_color: {
        required
      },
      button_primary_font_color: {
        required
      },
      button_secondary_font_color: {
        required
      },
      button_primary_border_color: {
        required
      },
      button_secondary_border_color: {
        required
      },
      button_border_radius: {
        required
      },
    },
  },
  mounted: function(){
    if(this.editTemplate){
      // try to search from admin/campaignTemplates
      const template = this.$store.state.admin.campaignTemplates.find(t => t.id == this.$route.params.itemId);
      if(template){
        this.template = {...template};
      } else {
        // no template available, fetch
        this.getTemplate();
      }
    }
  },
  computed: {
    editTemplate(){
      if(this.$route.params && this.$route.params.action == "edit"){
        return true;
      }
      return false;
    },
    activeStore(){
      return this.$store.state.activeStore && this.$store.state.activeStore.id ? true : false;
    },
    bgImageSet(){
      if(this.template.background_image || this.bgImgFileObj){
        return true;
      }
      return false;
    },
    bgImgFileName(){
      if(this.template.background_image){
        return this.template.background_image.split('/').slice(-1)[0];
      } else if(this.bgImgFileObj){
        return this.bgImgFileObj.name;
      }
      return null;
    },
    logoFileName(){
      if(this.template.logo){
        return this.template.logo.split('/').slice(-1)[0];
      } else if(this.logoFileObj){
        return this.logoFileObj.name;
      }
      return null;
    }
  },
  watch: {
    bgImgFileName: function(newVal, oldVal){
      if(!newVal && oldVal){
        this.template.bg_image_position = null;
        this.template.bg_image_repeat = null;
        this.template.bg_image_size = null;
      } else if(newVal && !oldVal){
        this.template.bg_image_position = "center top";
        this.template.bg_image_repeat = "no-repeat";
        this.template.bg_image_size = "auto";
      }
    },
    activeStore: function (newVal, oldVal) {
      if(!oldVal && newVal && newVal.id){
        if(this.editCampaign && !this.campaign.id){
          this.getCampaign();
        }
      }
    },
  },
  methods: {
    openBgPicker(value){
      this.colorPickerRef = value;
      if(value == 'background_colorpicker'){
        this.showBgPicker = true;
      } else if(value == "conbackground_colorpicker"){
        this.showConBgPicker = true;
      } else if (value == 'text_colorpicker') {
        this.showTextPicker = true
      }else if (value == 'text_body_colorpicker') {
        this.showTextBodyPicker = true
      }else if (value == 'button_primary_colorpicker') {
        this.showButtonPrimaryPicker = true
      }else if (value == 'button_secondary_colorpicker') {
        this.showButtonSecondaryPicker = true
      }else if (this.colorPickerRef == 'button_primary_text_colorpicker') {
        this.showButtonPrimaryTextPicker = true
      }else if (this.colorPickerRef == 'button_secondary_text_colorpicker') {
        this.showButtonSecondaryTextPicker = true
      }else if(this.colorPickerRef == "button_primary_border_colorpicker"){
        this.showButtonPrimaryBorderPicker = true
      }else if(this.colorPickerRef == "button_secondary_border_colorpicker"){
        this.showButtonSecondaryBorderPicker = true
      }else if(this.colorPickerRef == "header_bg_colorpicker"){
        this.showHeaderBgPicker = true
      }else if(this.colorPickerRef == "header_text_colorpicker"){
        this.showHeaderTextPicker = true
      }
      document.addEventListener('click', this.documentClick);
    },
    closeBgPicker(){
      document.removeEventListener('click', this.documentClick);
      if(this.colorPickerRef == 'background_colorpicker'){
        this.showBgPicker = false;
      } else if(this.colorPickerRef == "conbackground_colorpicker"){
        this.showConBgPicker = false;
      } else if (this.colorPickerRef == 'text_colorpicker') {
        this.showTextPicker = false
      } else if (this.colorPickerRef == 'text_body_colorpicker') {
        this.showTextBodyPicker = false
      }else if (this.colorPickerRef == 'button_primary_colorpicker') {
        this.showButtonPrimaryPicker = false
      }else if (this.colorPickerRef == 'button_secondary_colorpicker') {
        this.showButtonSecondaryPicker = false
      }else if (this.colorPickerRef == 'button_primary_text_colorpicker') {
        this.showButtonPrimaryTextPicker = false
      }else if (this.colorPickerRef == 'button_secondary_text_colorpicker') {
        this.showButtonSecondaryTextPicker = false
      }else if(this.colorPickerRef == "button_primary_border_colorpicker"){
        this.showButtonPrimaryBorderPicker = false
      }else if(this.colorPickerRef == "button_secondary_border_colorpicker"){
        this.showButtonSecondaryBorderPicker = false
      }else if(this.colorPickerRef == "header_bg_colorpicker"){
        this.showHeaderBgPicker = false
      }else if(this.colorPickerRef == "header_text_colorpicker"){
        this.showHeaderTextPicker = false
      }
    },
    updateFromPicker(color) {
      if(this.colorPickerRef == "background_colorpicker"){
        this.template.background_color = color.hex8
      } else if(this.colorPickerRef == "conbackground_colorpicker"){
        this.template.container_bg_color = color.hex8
      } else if(this.colorPickerRef == "text_colorpicker"){
        this.template.font_color_title= color.hex8
      } else if(this.colorPickerRef == "text_body_colorpicker"){
        this.template.font_color_body= color.hex8
      }else if(this.colorPickerRef == "button_primary_colorpicker"){
        this.template.button_primary_bg_color = color.hex8
      }else if(this.colorPickerRef == "button_secondary_colorpicker"){
        this.template.button_secondary_bg_color = color.hex8
      }else if(this.colorPickerRef == "button_primary_text_colorpicker"){
        this.template.button_primary_font_color = color.hex8
      }else if(this.colorPickerRef == "button_secondary_text_colorpicker"){
        this.template.button_secondary_font_color = color.hex8
      }else if(this.colorPickerRef == "button_primary_border_colorpicker"){
        this.template.button_primary_border_color = color.hex8
      }else if(this.colorPickerRef == "button_secondary_border_colorpicker"){
         this.template.button_secondary_border_color = color.hex8
      }else if(this.colorPickerRef == "header_bg_colorpicker"){
         this.template.header_background = color.hex8
      }else if(this.colorPickerRef == "header_text_colorpicker"){
         this.template.header_font_color = color.hex8
      }
      
    },
    documentClick(e) {
      var el = this.$refs[this.colorPickerRef],
        target = e.target;
      if(el !== target && !el.contains(target)) {
        this.closeBgPicker()
      }
    },
    handleBgImgFile(){
      let file = this.$refs.bgImgFile.files[0]
      if(file.size > 5000000){
        this.$refs.bgImgFile.value = null;
        this.$toastr.e("Print file size must be under 5MB");
        return;
      }
      if(!this.acceptedMimeTypes.includes(file.type)){
        this.$refs.bgImgFile.value = null;
        this.$toastr.e("Please choose gif, jpg, png or webp file.");
        return;
      }
      if(file.name.length > 80){
        const shorterName = this.shortenName(file.name)
        // replace the file with new one to update the name
        this.bgImgFileObj = new File([file], shorterName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        this.bgImgFileObj = file;
      }
      this.bgImgFileChanged = true;
      return;
    },
    removeBgImgFile(){
      const vm = this;
      if(!this.template.background_image){
        this.$refs.bgImgFile.value = '';
        this.bgImgFileObj = null;
        vm.updating = true;
        this.bgImgFileChanged = false;
        setTimeout(() => {
          vm.updating = false;
        }, 500);
        return;
      }
      this.updating = true;
      const data = {'background_image': null}
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/campaigntemplates/' + this.template.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        vm.$store.dispatch('updateCampaignTemplate', response.data)
        vm.$toastr.s("Background image removed successfully.");
        vm.template = {...response.data};
        vm.bgImgFileObj = null;
        vm.updating = false;
        setTimeout(() => {
          // make sure ref is visible before clearing
          vm.$refs.bgImgFile.value = '';
        }, 500);
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message){
          vm.$toastr.e(
            error.response.data.message
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    handleLogoFile(){
      let file = this.$refs.logoFile.files[0]
      if(file.size > 500000){
        this.$refs.logoFile.value = null;
        this.$toastr.e("Logo file size must be under 500kb");
        return;
      }
      if(!this.acceptedMimeTypes.includes(file.type)){
        this.$refs.logoFile.value = null;
        this.$toastr.e("Please choose gif, jpg, png or webp file.");
        return;
      }
      if(file.name.length > 80){
        const shorterName = this.shortenName(file.name)
        // replace the file with new one to update the name
        this.logoFileObj = new File([file], shorterName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        this.logoFileObj = file;
      }
      this.logoFileChanged = true;
      return;
    },
    removeLogoFile(){
      const vm = this;
      if(!this.template.logo){
        this.$refs.logoFile.value = '';
        this.logoFileObj = null;
        vm.updating = true;
        this.logoFileChanged = false;
        setTimeout(() => {
          vm.updating = false;
        }, 500);
        return;
      }
      this.updating = true;
      const data = {'logo': null}
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/campaigntemplates/' + this.template.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        vm.$store.dispatch('updateCampaignTemplate', response.data)
        vm.$toastr.s("Logo removed successfully.");
        vm.template = {...response.data};
        vm.logoFileObj = null;
        vm.updating = false;
        setTimeout(() => {
          // make sure ref is visible before clearing
          vm.$refs.logoFile.value = '';
        }, 500);
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message){
          vm.$toastr.e(
            error.response.data.message
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    getTemplate(){
      if(!this.activeStore){
        return;
      }
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/campaigntemplates/" + this.$route.params.itemId + "/";

      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        vm.template = {...response.data}
        vm.updating = false;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    saveTemplate(){
      this.closeBgPicker();
      
      this.updating = true;
      this.$v.$touch();
      if(this.$v.$invalid) {
        this.updating = false;
        this.$toastr.e(
          "Please check all fields."
        )
        return;
      }
      const vm = this;
      let data = {...this.template};

      // TODO: Handle background image and logo upload separately!
      delete data.background_image;
      delete data.logo;
      let url = ""
      let method = ""
      if(this.editTemplate){
        url = process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/campaigntemplates/" + this.template.id + "/";
        method = "PUT";
      } else {
        data.store = this.$store.getters.activeStore.id;
        url = process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/campaigntemplates/";
        method = 'POST';
      }

      this.$http({
        method: method,
        url: url,
        data: data,
      })
      .then(function (response) {
        vm.template = {...response.data}
        // update background image if there is a new one
        if((vm.bgImgFileObj && vm.bgImgFileObj.name) || (vm.logoFileObj && vm.logoFileObj.name)){
          url = process.env.VUE_APP_API_URL + "/api/stores/" + vm.$store.getters.activeStore.id + "/campaigntemplates/" + vm.template.id + "/";
          vm.uploadImages(url).then(function(result) {
            vm.template = {...result.data};
            if(vm.editTemplate){
              vm.$store.dispatch('admin/updateCampaignTemplate', result.data);
              vm.$toastr.s(
                "Your changes have been saved."
              );
            } else {
              vm.$toastr.s(
                "Template created successfully."
              );
              vm.$store.dispatch('admin/addCampaignTemplate', result.data);
              // redirect to created template page
              vm.$router.push({ name: 'campaigns', params: { tab: 'templates', action: 'view', itemId: result.data.id }});
            }
            vm.updating = false;
          } );
        }
        else{
          if(vm.editTemplate){
            vm.$store.dispatch('admin/updateCampaignTemplate', response.data);
            vm.$toastr.s(
              "Your changes have been saved."
            );
          } else {
            vm.$toastr.s(
              "Template created successfully."
            );
            vm.$store.dispatch('admin/addCampaignTemplate', response.data);
            // redirect to created template page
            vm.$router.push({ name: 'campaigns', params: { tab: 'templates', action: 'view', itemId: response.data.id }});
          }
          vm.updating = false;
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    shortenName(fileName){
      // Make sure the filename is max 80char + extension
      let newFileName = fileName.substring(0,fileName.lastIndexOf(".")).substring(0,80);
      const fileExt = fileName.substring(fileName.lastIndexOf(".")+1)
      if(!fileName.lastIndexOf(".")){
        // no dot in filename, just shorten the string
        newFileName = fileName.substring(0,80);
      } else {
        // add file extension to name
        newFileName += "." + fileExt;
      }
      return newFileName;
    },
    uploadImages(api_url){
      let templateFormData = new FormData();
      const vm = this;
      if(vm.bgImgFileObj && vm.bgImgFileObj.name){
        templateFormData.append('background_image', this.bgImgFileObj);
      }
      if(vm.logoFileObj && vm.logoFileObj.name){
        templateFormData.append('logo', this.logoFileObj);
      }

      let headers = {};
      headers['Content-Type'] = 'multipart/form-data';
      return this.$http({
        method: 'PATCH',
        url: api_url,
        data: templateFormData,
        headers: headers
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error);
        document.body.style.cursor='default';
      });
    },
  }
}

</script>