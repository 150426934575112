<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="archiveProductModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Recipients added to campaign</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body mt-4">
            <div class="row">
              <div class="col">
                <div class="font-weight-bold">Valid emails found</div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-break mb-1" v-for="email of emailArray" v-bind:key="email">
                <div style="float: left;">{{email}}</div>
                <div class="round-bg pointer ml-2" v-on:click="emailToBeRemoved(email)">x</div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <button type="button" class="btn btn-lg btn-outline-primary mt-2" v-on:click="closeModal()">Close</button>
            <button type="button" class="btn btn-lg btn-primary mt-2" v-on:click="removeEmailsFromCampaign()">Save and close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "EditEmailsModal",
  props: [
    "emails"
  ],
  data(){
    return {
    }
  },
  computed: {
    emailArray(){
      return Array.from(this.emails);
    }
  },
  methods: {
    emailToBeRemoved(email){
      // remove this email from the set
      this.$emit('emailToBeRemoved', email);
    },
    closeModal(){
      this.$emit('closeEditEmailModalAndClear');
    },
    removeEmailsFromCampaign(){
      this.$emit('removeEmailsFromCampaign');
    },
  }
}
</script>
