<template>
  <div>
    <CreateEditTemplate v-if="showSingleTemplate" />
    <TemplateListing v-else />
  </div>
</template>

<script>

import TemplateListing from '../components/TemplateListing.vue'
import CreateEditTemplate from '../components/CreateEditTemplate.vue'

export default {
  name: 'Templates',
  components: {
    TemplateListing,
    CreateEditTemplate
  },
  computed: {
    showSingleTemplate(){
      return ["new", "edit"].includes(this.$route.params.action)
    }
  },
}

</script>