<template>
  <div class="row">
    <div class="col-md-3" v-for="p in productObjects" v-bind:key="p.id">
      <div class="row">
        <div class="col-5">
          <img class="img-fluid" v-if="p.primary_thumb_big" :src="p.primary_thumb_big" />
          <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-7 pl-0">
          <div class="font-weight-bold">{{p.name}}</div>
          <div class="price">{{p.price}}{{$store.getters.currencyStr}} per item</div>
          <div>Delivered {{p.delivery_display.toLowerCase()}}</div>
          <div v-if="p.stock_management">
            <div v-if="!p.variants.find(v => v.stock_quantity > 0)">
              <span class="tag-red">Out of stock</span>
            </div>
            <div v-else-if="p.variants && p.variants.length == 1">
              <span class="tag-grey">{{ p.variants[0].stock_quantity }} in stock</span>
            </div>
            <div v-else-if="p.variants && p.variants.length > 1">
                  <span v-for="(v, index) of p.variants" v-bind:key="index" :class="{'tag-grey': v.stock_quantity, 'tag-red': !v.stock_quantity}">
                    {{ v.name }} {{ v.stock_quantity }}
                  </span>
            </div>
          </div>
          <div v-else><span class="tag-grey">Produced after order</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductSet",
  props: [
      "productObjects"
  ],
}
</script>
