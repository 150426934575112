<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav />
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row d-flex align-items-center justify-content-between">
        <div class="col-12 col-md-6 mt-md-5">
          <h1 class="mt-4">Magic Link campaigns</h1>
        </div>
        <div v-if="!this.$route.params.action" class="col-sm-12 col-md-4 col-lg-3 mt-md-5 d-none d-md-block">
            <SamSecondaryButton @buttonAction="addNewItem(campaignsTab ? 'campaign' : 'templates')" :text="`+ Add new ${campaignsTab ? 'campaign' : 'template'}`" noSpace/>
        </div>
      </div>
      <!-- <CampaignTabs v-bind:campaignsTab=campaignsTab v-bind:templatesTab=templatesTab /> -->
      <SamTabs :tabs="tabs" :defaultTab="defaultTab" noAccess/>
      <CreateEditCampaign v-if="showCreateEditCampaign && campaignsTab"/>
      <CampaignListing v-else-if="campaignsTab" />
      <Templates v-else-if="templatesTab" />
    </div>
    <Footer />
  </div>
</template>

<script>
// import CampaignTabs from '../components/CampaignTabs.vue'
import CampaignListing from '../components/CampaignListing.vue'
import CreateEditCampaign from '../components/CreateEditCampaign.vue'
import Templates from './Templates.vue'
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";
import SamTabs from '../../../components/SamTabs.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'Campaigns',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    // CampaignTabs,
    CampaignListing,
    CreateEditCampaign,
    Templates,
    SamTabs,
    SamSecondaryButton
  },
  data() {
    return {
      tabs: [
        {
          name: 'Campaigns',
          access: '',
          path: 'campaigns',
          tab: 'campaign'
        },
        {
          name: 'Templates',
          access: '',
          path: 'campaigns',
          tab: 'templates'
        },
      ]
    }
  },
  mounted: function(){
    // redirect if group data fetched and no manage store permissions
    if(this.$store.state.membership.group_data && !this.$store.state.membership.group_data['campaign_create']){
      this.$router.push({ name: 'productlist' });
    }
  },
  computed: {
    updating(){
      return this.membership.group_data && this.membership.group_data['campaign_create'] ? false : true;
    },
    showCreateEditCampaign(){
      return this.$route.params.tab == "campaign" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    editCampaign(){
      return this.$route.params.action == "edit" ? true : false;
    },
    membership(){
      return this.$store.getters.membership;
    },
    campaignsTab(){
      return this.$route.params.tab == "campaign" || !this.$route.params.tab;
    },
    templatesTab(){
      return this.$route.params.tab == "templates";
    },
    defaultTab(){
      const noMatch = !this.campaignsTab && !this.templatesTab
      return !this.$route.params.tab ? 'campaign' : noMatch;
    },
  },
  watch: {
    membership: function (newMem) {
      // when membership is updated, check if user has manage store permissions
      if(newMem.group_data && !newMem.group_data['campaign_create']){
        this.$router.push({ name: 'productlist' });
      }

    }
  },
  methods: {
    addNewItem(item) {
      this.$router.push({ name: 'campaigns', params: { tab: item, action: 'new' }});
    }
  }
}
</script>
