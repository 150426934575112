<template>
  <div>
    <div v-if="!campaignsFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SamAccordion
        v-if="windowWidth < mobileView"
        :class="'.d-none .d-sm-block .d-md-none'"
        :title="`${filteredCampaigns.length > 1 ? 'Campaigns': 'Campaign'} (${filteredCampaigns.length}) `"
        title-payload="filters"
        accordionId="samAccordianCampaign"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="campaignFilter"
          :options="campaignsNamesList"
          :reduce="c => c.value"
          :searchable="true"
          placeholder="All campaigns"
      ></v-select>
      <v-select
          class="sam-input sam-select mb-4"
          v-model="campaignStatusFilter"
          :options="statusList"
          :reduce="s => s.label"
          :searchable="true"
          placeholder="All statuses"
      ></v-select>
      <v-select
          class="sam-input sam-select mb-4"
          v-model="campaignCreatorFilter"
          :options="campaignsCreatorsList"
          :reduce="cc => cc.value"
          :searchable="true"
          placeholder="All campaign creators"
      ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new campaign" noSpace/>
      </SamAccordion>
      <SamTableRow v-else headerRow>
        <SamTableColumn>
          <v-select
            class="sam-input sam-select"
            v-model="campaignFilter"
            :options="campaignsNamesList"
            :reduce="c => c.value"
            :searchable="true"
            placeholder="All campaigns"
          
        ></v-select>
        </SamTableColumn>
        <SamTableColumn>
           <v-select
              class="sam-input sam-select"
              v-model="campaignStatusFilter"
              :options="statusList"
              :reduce="s => s.label"
              :searchable="true"
              placeholder="All statuses"
            
          ></v-select>
        </SamTableColumn>
        <SamTableColumn>
          <SamParagraph text="Start and end date"/>
        </SamTableColumn>
        <SamTableColumn>
          <v-select
            class="sam-input sam-select"
            v-model="campaignCreatorFilter"
            :options="campaignsCreatorsList"
            :reduce="cc => cc.value"
            :searchable="true"
            placeholder="All campaign creators"
        
        ></v-select>
        </SamTableColumn>
      </SamTableRow>
       <div 
       @click="openEdit(campaign)"
        v-for="campaign in filteredCampaigns" 
        v-bind:key="campaign.id"
      >
      <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Campaign name" bolded/>
            <SamParagraph :text="campaign.name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
            <SamTag
              :text="`${campaign.expired ? 'Expired' : 'Active'}`"
              :active="!campaign.expired"
              tableItem
            />
            <SamParagraph 
              :text="`${campaign.invitations.filter(c => c.used).length + (campaign.multi_invitation ? campaign.multi_invitation.usage_count : 0)} / ${campaign.invitations.length + (campaign.multi_invitation ? campaign.multi_invitation.orders.length : 0)} orders (${((campaign.invitations.filter(c => c.used).length + (campaign.multi_invitation ? campaign.multi_invitation.usage_count : 0))/(campaign.invitations.length + (campaign.multi_invitation ? campaign.multi_invitation.orders.length : 0))*100).toFixed(1)}%)`" 
              tableItem
            />
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Start and end date" bolded/>
            <SamParagraph :text="`${parseDate(campaign.start_date)} -`" tableItem/>
            <SamParagraph :text="parseDate(campaign.expiration_date)" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Campaign creator" bolded/>
            <SamParagraph :text="campaign.creator_name" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(campaign)"  text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(group)" />
        </SamTableRow>
     </div>
      <div>
        <div class="row" v-if="$store.state.admin.moreCampaignsAvailable">
          <div class="col-5">
          </div>
          <div class="col-2">
            <div class="row justify-content-center">
              <div class="mini-loader">Loading...</div>
            </div>
            <div class="mt-2">
              Loading more results..
            </div>
          </div>
          <div class="col-5">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamTag from '../../../components/SamTag.vue'

export default {
  name: 'CampaignListing',
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton,
    SamTag
  },
  data() {
    return {
      updating: false,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
      campaignFilter: null,
      campaignStatusFilter: null, 
      campaignCreatorFilter: null,
      statusList: [{"label": 'Expired', "value": true}, {"label": 'Active', "value": false}]
    }
  },
  mounted: function () {
     //Check window width
      setTimeout(() => this.isFetching = true, 1000);
      window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
      window.removeEventListener('resize', this.getDimensions);
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    campaignsFetched(){
      if(!this.$store.state.admin.campaignsFetched){
        //for whole campaigns name list filter
        this.$store.dispatch('admin/fetchCampaigns', {pageSize: 250});
      }
      return this.$store.state.admin.campaignsFetched;
    },

    campaignsNamesList(){
     const names = this.campaigns.map(c => ({label: c.name, value: c.id}))
      return [...names]
    },
    campaigns(){
      return this.$store.state.admin.campaigns;
    },

    campaignsStatusesList(){
     const statuses = this.statusList.map(c => ({label: c.label, value: c.label}))
      return [...statuses]
    },
    campaignsCreatorsList(){
     const names = this.campaigns.map(c => ({label: c.creator_name, value: c.creator}))

     const filteredNames = names.reduce((acc, current) => {
      const x = acc.find(item => item.value === current.value);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
      return [...filteredNames]
    },


    filteredCampaigns(){
      let campaigns = this.campaigns;
      if(this.campaignFilter){
        campaigns = campaigns.filter(c => c.id == this.campaignFilter)
      }
      if(this.campaignStatusFilter == 'Expired'){
        campaigns = campaigns.filter(c => c.expired && this.campaignStatusFilter === 'Expired' )
      }
      if(this.campaignStatusFilter == 'Active'){
        campaigns = campaigns.filter(c => c.expired == false && this.campaignStatusFilter === 'Active' )
      }
      if(this.campaignCreatorFilter){
        campaigns = campaigns.filter(c => c.creator == this.campaignCreatorFilter)
      }
      return campaigns;
    },
   
  },
  methods:{
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('admin/fetchCampaigns', {append: true});
      }
    },
    addNewItem() {
      this.$router.push({ name: 'campaigns', params: { tab: 'campaign', action: 'new' }});
    },
     openEdit(item) {
       this.$router.push({ name: 'campaigns', params: { tab: 'campaign', action: 'edit', itemId: item.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
