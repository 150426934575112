<template>
  <div>
    <div class="modal fade in show modal-active modal-open">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title m-3">Are you sure?</h4>
            <button type="button" class="close" @click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body m-3">
            <div>By deleting this campaign, you will also delete all the orders associated with this campaign. This can't be undone.</div>
          </div>
          <!-- Modal body end -->
          <div class="modal-footer justify-content-center">
            <div class="row w-100">
                <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-lg btn-danger rounded my-3 w-100" @click="deleteCampaign()">Delete campaign</button>
                </div>
                <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-lg btn-outline-primary rounded my-3 w-100" @click="closeModal()">Cancel</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
export default {
    name: "DeleteCampaignModal",
    methods: {
        closeModal() {
            this.$emit('closeDeleteCampaignModal')
        },
        deleteCampaign() {
            this.$emit('deleteCampaign')
        }
    }
}
</script>
