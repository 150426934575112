<template>
  <div>
    <div v-if="!templatesFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SamAccordion
        v-if="windowWidth < mobileView"
        :class="'.d-none .d-sm-block .d-md-none'"
        :title="`${filteredTemplate.length > 1 ? 'Templates': 'Template'} (${filteredTemplate.length}) `"
        title-payload="filters"
        accordionId="samAccordianTemplates"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="templateNameFilter"
          :options="templateNamesList"
          :reduce="n => n.value"
          :searchable="true"
          placeholder="All templates"
        ></v-select>
        <v-select
            class="sam-input sam-select mb-4"
            v-model="templateCreatorFilter"
            :options="templateCreatorsList"
            :reduce="cc => cc.value"
            :searchable="true"
            placeholder="All template creators"
        ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new template" noSpace/>
      </SamAccordion>
      <SamTableRow v-else headerRow>
        <SamTableColumn>
          <v-select
            class="sam-input sam-select"
            v-model="templateNameFilter"
            :options="templateNamesList"
            :reduce="c => c.value"
            :searchable="true"
            placeholder="All template"
          
        ></v-select>
        </SamTableColumn>
        <SamTableColumn>
          <v-select
            class="sam-input sam-select"
            v-model="templateCreatorFilter"
            :options="templateCreatorsList"
            :reduce="cc => cc.value"
            :searchable="true"
            placeholder="All template creators"
        
        ></v-select>
        </SamTableColumn>
         <SamTableColumn>
          <SamParagraph text="Create date"/>
        </SamTableColumn>
      </SamTableRow>
      <div 
       @click="openEdit(template)"
        v-for="template in filteredTemplate" 
        v-bind:key="template.id"
      >
      <SamTableRow hoverable noSpace clickableRow>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Template name" bolded/>
          <SamParagraph :text="template.name" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Template creator" bolded/>
            <SamParagraph :text="template.creator_name" tableItem/>
          </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Create date" bolded/>
          <SamParagraph :text="parseDate(template.created)" tableItem/>
        </SamTableColumn>
        <SamTableColumn v-if="windowWidth < mobileView">
          <SamSecondaryButton @buttonAction="openEdit(template)"  text="Edit" noSpace/>
        </SamTableColumn>
        <SamTableColumn></SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(template)" />
        </SamTableRow>
      </div>
      <div>
        <div class="row" v-if="$store.state.admin.moreCampTemplatesAvailable">
          <div class="col-5">
          </div>
          <div class="col-2">
            <div class="row justify-content-center">
              <div class="mini-loader">Loading...</div>
            </div>
            <div class="mt-2">
              Loading more results..
            </div>
          </div>
          <div class="col-5">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'TemplateListing',
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton,
  },
  data() {
    return {
      updating: false,
      templateNameFilter: null,
      templateCreatorFilter: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
   mounted: function () {
     //Check window width
      setTimeout(() => this.isFetching = true, 1000);
      window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
      window.removeEventListener('resize', this.getDimensions);
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed:{
    templatesFetched(){
      if(!this.$store.state.admin.campTemplatesFetched){
          //for whole campaigns name list filter
        this.$store.dispatch('admin/fetchCampaignTemplates', {pageSize: 250});
      }
      return this.$store.state.admin.campTemplatesFetched;
    },
    templates(){
      return this.$store.state.admin.campaignTemplates;
    },
    templateNamesList(){
      
     const names = this.templates.map(t => ({label: t.name, value: t.id}))
      return [...names]

    },
    templateCreatorsList(){
     const names = this.templates.map(t => ({label: t.creator_name, value: t.creator_name}));

     const filteredNames = names.reduce((acc, current) => {
      const x = acc.find(item => item.value === current.value);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
      return [...filteredNames]
    },
    filteredTemplate(){
      let templates = this.templates;
      if(this.templateNameFilter){
        templates = templates.filter(t => t.id == this.templateNameFilter)
      }
      if(this.templateCreatorFilter){
        templates = templates.filter(t => t.creator_name == this.templateCreatorFilter)
      }
      return templates;
    },
  },
  methods:{
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('admin/fetchCampaignTemplates', {append: true});
      }
    },
    addNewItem() {
      this.$router.push({ name: 'campaigns', params: { tab: 'templates', action: 'new' }});
    },
     openEdit(item) {
       this.$router.push({ name: 'campaigns', params: { tab: 'templates', action: 'edit', itemId: item.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
