<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="archiveProductModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add products to campaign</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body mt-4">
            <div v-if="!productsFetched">
              <div class="loader-container">
                <div class="row justify-content-center">
                  <div class="col-md-4 d-flex justify-content-center">
                    <div class="loader">Loading...</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mb-4" v-for="product in products" v-bind:key="product.id">
                <div class="col-2">
                  <img v-if="product.primary_thumb_big" class="img-fluid" :src="product.primary_thumb_big" />
                  <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-5">
                  <div class="font-weight-bold">{{product.name}}</div>
                  <div>{{product.price}}{{$store.getters.currencyStr}} per item</div>
                  <div>Delivered {{product.delivery_display.toLowerCase()}}</div>
                  <div v-if="product.stock_management">
                    <div v-if="!product.variants.find(v => v.stock_quantity > 0)">
                      <span class="tag-red">Out of stock</span>
                    </div>
                    <div v-else-if="product.variants && product.variants.length == 1">
                      <span class="tag-grey">{{ product.variants[0].stock_quantity }} in stock</span>
                    </div>
                    <div v-else-if="product.variants && product.variants.length > 1">
                      <span v-for="(v, index) of product.variants" v-bind:key="index" :class="{'tag-grey': v.stock_quantity, 'tag-red': !v.stock_quantity}">
                        {{ v.name }} {{ v.stock_quantity }}
                      </span>
                    </div>
                  </div>
                  <div v-else><span class="tag-grey">Produced after order</span></div>
                </div>
                <div class="col-5 d-flex align-items-center">
                  <button v-if="productIds.includes(product.id)" type="button" class="btn btn-lg btn-primary btn-block mt-2 w-100" v-on:click="removeFromCampaign(product.id)">Remove</button>
                  <button v-else type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="addToCampaign(product.id)">Add</button>
                </div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
export default {
  name: "AddProductsModal",
  props: [
    "productIds",
  ],
  data(){
    return {
    }
  },
  computed: {
    productsFetched(){
      if(!this.$store.state.admin.campaignProductsFetched){
        this.$store.dispatch('admin/fetchProductsForCampaigns');
      }
      return this.$store.state.admin.campaignProductsFetched;
    },
    products(){
      return this.$store.state.admin.campaignProducts;
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeAddProductsModal');
    },
    addToCampaign(productId){
      this.$emit('addLegacyProductToCampaign', productId);
    },
    removeFromCampaign(productId){
      this.$emit('removeLegacyProductFromCampaign', productId);
    },
  }
}
</script>
